/** component **/
/** SidebarMember > SelectMemberEdit **/
.select-container > button {
  > span,
  > div {
    position: relative !important;
    top: 0;
    right: 0;
    padding-top: 0;
    margin-top: 0;
    transform: translateY(0);
  }

  > span {
    padding: 0 4px;
  }
}

/* DatePicker.tsx */
.react-datepicker-wrapper {
  position: relative;
  .react-datepicker__input-container {
    position: unset;
    .react-datepicker-ignore-onclickoutside{
      border-color: #592c12; 
    }
  }
}

.react-datepicker__tab-loop {
  .react-datepicker-popper {
    top: 100% !important;
    transform: none !important;
    width: 100%;
    .react-datepicker {
      border: none;
    }
    .react-datepicker__navigation {
      .react-datepicker__navigation-icon {
        &::before {
          border-color: #333;
        }
      }
    }

    .react-datepicker {
      width: 100%;
      .react-datepicker__children-container {
        width: 100% !important;
      }

      .react-datepicker__month-container {
        width: 100%;
        .react-datepicker__header {
          background-color: #fff;
          border: none;
          .react-datepicker__day-names {
            display: flex;
            justify-content: space-between;
            padding: 0 10px;
          }
        }
        .react-datepicker__week {
          display: flex;
          justify-content: space-between;
          .react-datepicker__day {
            width: 40px;
            height: 40px;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            &:hover {
              background-color: #592c12c7 !important;
              color: #fff;
            }
          }
          .react-datepicker__day--selected {
            background-color: #592c12 !important;
          }
          .react-datepicker__day--keyboard-selected {
            background-color: #fff;
          }
        }
      }
    }
  }

  .react-datepicker__triangle {
    display: none;
  }
}

.react-datepicker__day--today {
  background-color: #fff !important;
  border: 1px solid #592c12 !important;
  border-radius: 50% !important;
}
.filter_conversation .wrap-select-sort span,
.filter_conversation .wrap-select-sort .place-items-center{
  color: black;
}
/** component **/
.sidebar-scroll-style::-webkit-scrollbar-track
{
	background-color: transparent;
}

.sidebar-scroll-style::-webkit-scrollbar
{
  height: 2px;
	background-color: transparent;
}

.sidebar-scroll-style::-webkit-scrollbar-thumb
{
	background-color: #555555;
	border: 1px solid #555555;
}
.sidebar-search-style::-webkit-scrollbar {
  width: 6px;
  background-color: #FFF;
}

.sidebar-search-style::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #E7E7E7;
}